import APIService from '@/services/API';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

const UnitSelection: React.FC = ({ ...props }) => {
  const { data: categories, isLoading } = useQuery(['unites'], () =>
    APIService.getUnits({
      $limit: '100',
    }),
  );
  const { i18n } = useTranslation();
  return (
    <Select
      style={{ width: '100%' }}
      loading={isLoading}
      showSearch
      options={categories?.data?.map((row) => ({
        label: `${row.unit} - ${row.name[i18n.language as 'en' | 'es']}`,
        value: row._id,
      }))}
      {...props}
    />
  );
};

export default UnitSelection;
