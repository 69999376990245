import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Modal,
  Popover,
  Row,
  Skeleton,
  notification,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import APIService from '@/services/API';
import {
  HomeOutlined,
  ShopOutlined,
  EyeOutlined,
  SettingFilled,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import PropertyForm from '@/components/forms/Property.form';
import { Attribute, Catalog } from '@/interfaces/property.interface';
import { getValidationDescription } from '@/utils/validations';

const getPreviewUrl = () => import.meta.env.VITE_APP_PREVIEW_URL;

export interface PropertyDetailViewProps {
  edit?: boolean;
}
const PropertyDetailView: React.FC<PropertyDetailViewProps> = ({ edit = false }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();
  const nav = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: property, isLoading = true } = useQuery(
    ['property', id],
    () => APIService.getProperty(id || ''),
    {
      enabled: edit,
      onError: () => {
        nav('/dashboard/properties');
      },
      onSuccess(data) {
        const catalogs: any = {
          en: {},
          es: {},
        };
        const attributes: any = {
          en: {},
          es: {},
        };
        data?.categories.en.forEach((row) => {
          catalogs.en[row.category] = row.meta;
        });
        data?.categories.es.forEach((row) => {
          catalogs.es[row.category] = row.meta;
        });

        data?.attributes?.forEach((row) => {
          attributes[row.language][row.slug] = row;
        });
        form.setFieldsValue({ ...data, catalogs, _attributes: attributes });
      },
      retry: false,
      cacheTime: 0,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    },
  );
  const popoverContent = (
    <Flex>
      <Button
        onClick={() => {
          Modal.confirm({
            title: t('general.copyPropertyLanguageConfirmTitle'),
            content: t('general.copyPropertyLanguageConfirmMessage'),
            centered: true,
            onOk: () => {
              handleCopyProperty();
            },
          });
        }}
        block
      >
        <CopyOutlined />
        {t('general.copyPropertyLanguageLbl')}
      </Button>
    </Flex>
  );

  const toolbarOptions = (
    <Row justify="end">
      <Col>
        {id && (
          <span style={{ marginRight: 12 }}>
            <strong>{t('general.status')}: </strong>
            {t(`status.${property?.status}`)}
          </span>
        )}
        <Popover content={popoverContent} trigger="hover" placement="bottom">
          <Button type="dashed" icon={<SettingFilled />} style={{ marginRight: 12 }} />
        </Popover>
        {id && property?.slug && (
          <Button
            style={{ marginRight: 12 }}
            rel="noreferrer"
            href={`${getPreviewUrl()}/${language}/property/${
              property.slug[language as 'en' | 'es']
            }`}
            target="_blank"
            icon={<EyeOutlined />}
          />
        )}
        <Button
          type="default"
          onClick={() => beforeSubmitStatus('draft')}
          loading={loading}
          style={{ marginRight: 12 }}
        >
          {t('general.saveAsDraft')}
        </Button>
        <Button
          type="primary"
          onClick={() => beforeSubmitStatus('publish')}
          loading={loading}
        >
          {edit && id && property?.status !== 'draft'
            ? t('general.update')
            : t('general.publish')}
        </Button>
        {id && property?.status !== 'soft-delete' ? (
          <Button
            type="primary"
            onClick={() => beforeSubmitStatus('draft')}
            loading={loading}
            style={{ marginLeft: 12 }}
            danger
          >
            <DeleteOutlined />
          </Button>
        ) : null}
      </Col>
    </Row>
  );

  const handleCopyProperty = () => {
    const propertyFormData: Catalog & { _attributes: any } = form.getFieldsValue();
    const attributes: any = {
      en: {},
      es: {},
    };
    for (const row of property?.attributes || []) {
      attributes[row.language][row.slug] = row;
    }
    if (language === 'en') {
      propertyFormData.title.en = propertyFormData.title.es;
      propertyFormData.slug.en = propertyFormData.slug.es;
      propertyFormData.content.en = propertyFormData.content.es;
      for (const key in attributes.es) {
        propertyFormData._attributes.en[key] = {
          ...attributes.es[key],
          language: 'en',
        };
      }
    } else {
      propertyFormData.title.es = propertyFormData.title.en;
      propertyFormData.slug.es = propertyFormData.slug.en;
      propertyFormData.content.es = propertyFormData.content.en;
      for (const key in attributes.en) {
        propertyFormData._attributes.es[key] = {
          ...attributes.en[key],
          language: 'es',
        };
      }
    }
    form.setFieldsValue(propertyFormData);
  };

  const beforeSubmitStatus = (status: string) => {
    form.setFieldValue('status', status);
    return form.submit();
  };

  const onSubmit = async (values: Catalog & { _attributes: any }) => {
    const { catalogs, _attributes, ...rest } = values;
    const attributes = [];
    const newValues = {
      ...rest,
      categories: {
        en: Object.keys(catalogs.en).map((key: string) => ({
          meta: catalogs.en[key] || [],
          category: key,
        })),
        es: Object.keys(catalogs.es).map((key: string) => ({
          meta: catalogs.es[key] || [],
          category: key,
        })),
      },
    };
    for (const key in _attributes) {
      for (const subkey in _attributes[key]) {
        attributes.push(_attributes[key][subkey]);
      }
    }
    newValues.attributes = attributes;
    setLoading(true);
    const response = id
      ? await APIService.updateProperty(id, newValues)
      : await APIService.createProperty(newValues);
    if (response.ok) {
      notification.success({
        message: id ? t('general.updateSuccess') : t('general.createSuccess'),
      });
      nav('/dashboard/properties/' + response?.data?._id);
      if (response?.data && property) {
        property.status = response.data.status;
        property.slug = response.data.slug;
      }
    } else if (response?.data) {
      const description = getValidationDescription(response.data, form, t);

      notification.error({
        message: response.data?.message || t('general.saveError'),
        description,
      });
    }
    setLoading(false);
  };

  if (isLoading) {
    return <Skeleton active className="fadeIn" />;
  }
  return (
    <Card className="fadeIn">
      <Row justify="space-between" align="middle">
        <Col>
          <Breadcrumb
            items={[
              {
                href: '#',
                onClick: () => nav('/dashboard'),
                title: <HomeOutlined />,
              },
              {
                href: '#',
                onClick: () => nav('/dashboard/properties'),
                title: (
                  <>
                    <ShopOutlined />
                    <span>{t('menu.properties')}</span>
                  </>
                ),
              },
              {
                title: edit
                  ? property?.title[language as 'en' | 'es']
                  : t('general.create'),
              },
            ]}
          />
        </Col>
      </Row>
      {toolbarOptions}
      <PropertyForm formRef={form} onSubmit={onSubmit} edit={edit} />
      {toolbarOptions}
    </Card>
  );
};

export default PropertyDetailView;
