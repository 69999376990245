import { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Image, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { Gallery } from '@/interfaces/property.interface';

export interface ImageDndProps {
  image: Gallery;
  index: number;
  onDelete?: () => void;
  onMove?: (dragIndex: number, hoverIndex: number) => void;
}
const ImageDnd: FC<ImageDndProps> = ({ image, index, onMove, onDelete }) => {
  const ref: any = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientX = (clientOffset?.x ?? 0) - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      // Time to actually perform the action
      onMove?.(dragIndex, hoverIndex);
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { id: image._id, index },
    type: 'card',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      ref={ref}
      className="fake-gallery-image"
      key={index}
      style={{
        backgroundImage: `url(${image.url})`,
        opacity,
        cursor: 'move',
        position: 'relative',
      }}
    >
      <Image key={index} src={image.url} />
      <Button
        type="primary"
        shape="circle"
        icon={<CloseOutlined />}
        onClick={onDelete}
        size="middle"
        style={{
          left: -10,
          position: 'absolute',
          top: -10,
        }}
      ></Button>
    </div>
  );
};

export default ImageDnd;
