import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FileIcon from '../files/FileIcon';
import { getFileInfo } from '@/utils/files';
import FilePicker from '../files/FilePicker';

export interface FileData {
  title: string;
  description: string;
  url: string;
}
export interface FileSelectorProps {
  files: FileData[];
  slug: string;
  onDelete?: (slug: string, index: number) => void;
  onAdd?: (slug: string, image: FileData) => void;
  onChange?: (slug: string, index: number, images: FileData) => void;
}
const FileSelector: React.FC<FileSelectorProps> = ({
  slug,
  files,
  onDelete,
  onChange,
  onAdd,
}) => {
  const [formRef] = Form.useForm();
  const [editingIndex, setEditingIndex] = React.useState<number>(-1);
  const [modal, setModal] = React.useState(false);
  const { t } = useTranslation();

  const handleCloseModal = () => {
    setModal(false);
    setEditingIndex(-1);
    setTimeout(() => {
      formRef.resetFields();
    }, 500);
  };

  const handleSave = () => {
    const values = formRef.getFieldsValue();
    if (editingIndex > -1) {
      onChange?.(slug, editingIndex, values);
    } else {
      onAdd?.(slug, values);
    }
    handleCloseModal();
  };

  const handleDelete = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (onDelete) {
      onDelete(slug, editingIndex);
    }
  };

  return (
    <div>
      <Modal
        title="File"
        open={modal}
        onCancel={handleCloseModal}
        centered
        okText={t('general.save')}
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                key="delete"
                type="primary"
                danger
                onClick={() => {
                  onDelete?.(slug, editingIndex);
                  handleCloseModal();
                }}
                style={{
                  display: editingIndex > -1 ? 'inline' : 'none',
                }}
              >
                {t('general.delete')}
              </Button>
            </Col>
            <Col>
              <Button
                key="cancel"
                type="default"
                onClick={handleCloseModal}
                style={{ marginRight: 10 }}
              >
                {t('general.close')}
              </Button>
              <Button key="submit" type="primary" onClick={handleSave}>
                {t('general.save')}
              </Button>
            </Col>
          </Row>
        }
      >
        <Form
          form={formRef}
          initialValues={{
            title: '',
            description: '',
            url: '',
          }}
        >
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {(form) => {
                  return (
                    <Form.Item label="File" name="url">
                      <FilePicker
                        onChange={(value, file) => {
                          form.setFieldValue('url', value);
                          form.setFieldValue('title', file.name);
                        }}
                        value={form.getFieldValue('url')}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('general.title')} name="title">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={t('general.description')} name="description">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Row>
        {files.map((file, index) => {
          const fileType = getFileInfo(file.url);
          return (
            <Col
              flex="none"
              key={index}
              onClick={() => {
                formRef.setFieldsValue(files[index]);
                setEditingIndex(index);
                setModal(true);
              }}
              style={{
                position: 'relative',
                paddingRight: 16,
                marginBottom: 16,
              }}
            >
              <FileIcon
                fileType={fileType.extension}
                style={{ fontSize: 100, cursor: 'pointer' }}
              />
              <div
                style={{
                  fontSize: '0.75rem',
                  textAlign: 'center',
                  width: 100,
                  wordBreak: 'break-all',
                }}
              >
                {file.title}
              </div>
              <Button
                type="primary"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={handleDelete}
                size="small"
                style={{
                  left: -8,
                  position: 'absolute',
                  top: 4,
                  zIndex: 9,
                }}
              ></Button>
            </Col>
          );
        })}
      </Row>
      <Button
        type="dashed"
        style={{ width: '100%', marginTop: 10 }}
        onClick={() => {
          setModal(true);
        }}
      >
        {t('general.add')}
        <PlusOutlined />
      </Button>
    </div>
  );
};

export default FileSelector;
