import { RawPropertyField } from '@/interfaces/propertyField.interface';
import { Col, Form, FormInstance, Input, InputNumber, Row } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import JoditForm from './JoditForm';
import CurrencySelector from './CurrencySelector';
import UnitSelection from './UnitSelection';
import FileSelector, { FileData } from './FileSelector';
import LocationPicker from './LocationPicker';
import MapsToolsModal from '../modals/MapsTools.modal';

export interface AttributesModalChangeEventDetail {
  visible: boolean;
}
export interface AttributesListProps {
  propertyFields?: RawPropertyField[];
  formRef: FormInstance<unknown>;
  language: string;
}
const AttributesList: FC<AttributesListProps> = ({
  propertyFields,
  formRef,
  language,
}) => {
  propertyFields?.sort((a, b) => a.sort - b.sort);
  const { t } = useTranslation();

  /** File methods */
  function addFile(slug: string, image: FileData) {
    const value = formRef.getFieldValue(['_attributes', language, slug, 'value']) || [];
    formRef.setFieldValue(['_attributes', language, slug, 'value'], [...value, image]);
  }

  function removeFile(slug: string, index: number) {
    const files: FileData[] =
      formRef.getFieldValue(['_attributes', language, slug, 'value']) || [];
    files.splice(index, 1);
    formRef.setFieldValue(['_attributes', language, slug, 'value'], files);
  }

  function updateFiles(slug: string, index: number, image: FileData) {
    formRef.setFieldValue(['_attributes', language, slug, 'value', index], image);
  }

  function renderAttribute(row: RawPropertyField) {
    const field = propertyFields?.find((field) => field.slug === row.slug);

    const props = {
      label: (
        <>
          <Row justify="space-between" gutter={[10, 10]}>
            <Col>{field?.name[language as 'en' | 'es'] || row?.slug || ''}</Col>
          </Row>
        </>
      ),
      key: row._id || row.slug,
      name: ['_attributes', language, row.slug, 'value'],
      initialValue: '',
      extra: row?.description,
    };
    if (field?.type === 'html') {
      return (
        <Form.Item {...props} name={undefined}>
          <Form.Item
            style={{ display: 'none' }}
            name={['_attributes', language, row.slug, 'value']}
          >
            <Input />
          </Form.Item>
          <JoditForm form={formRef} path={['_attributes', language, row.slug, 'value']} />
        </Form.Item>
      );
    }
    if (field?.type === 'number') {
      return (
        <Form.Item {...props}>
          <InputNumber min={0} style={{ width: '100%' }} />
        </Form.Item>
      );
    }
    if (field?.type === 'mesure') {
      return (
        <Row justify="space-between" gutter={[10, 10]}>
          <Col span={8}>
            <Form.Item
              {...props}
              name={['_attributes', language, row.slug, 'unit']}
              label={t('general.unit')}
              initialValue="m²"
            >
              <UnitSelection />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item {...props}>
              <Input style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    if (field?.type === 'price') {
      // TODO add api currencies
      return (
        <Row justify="space-between" gutter={[10, 10]}>
          <Col span={8}>
            <Form.Item
              {...props}
              name={['_attributes', language, row.slug, 'unit']}
              label={t('general.currency')}
              initialValue="MXN"
            >
              <CurrencySelector />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item {...props}>
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              />
            </Form.Item>
          </Col>
        </Row>
      );
    }
    if (field?.type === 'map') {
      return (
        <>
          <Row gutter={[10, 10]}>
            <Col span={24}>{props.label}</Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col flex="none">
              <Form.Item
                {...props}
                name={['_attributes', language, row.slug, 'value', 'lat']}
                label={false}
                initialValue={0}
              >
                <InputNumber style={{ width: '100%' }} readOnly />
              </Form.Item>
            </Col>
            <Col flex="none">
              <Form.Item
                {...props}
                name={['_attributes', language, row.slug, 'value', 'lng']}
                label={false}
                initialValue={0}
              >
                <InputNumber style={{ width: '100%' }} readOnly />
              </Form.Item>
            </Col>
            <Col span={4}>
              <MapsToolsModal
                onOk={(location) =>
                  formRef.setFieldValue(
                    ['_attributes', language, row.slug, 'value'],
                    location,
                  )
                }
              />
            </Col>
          </Row>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return (
                    <LocationPicker
                      location={formRef.getFieldValue([
                        '_attributes',
                        language,
                        row.slug,
                        'value',
                      ])}
                      onChange={(location) =>
                        formRef.setFieldValue(
                          ['_attributes', language, row.slug, 'value'],
                          location,
                        )
                      }
                    />
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
        </>
      );
    }
    if (field?.type === 'attachment') {
      return (
        <>
          <Form.Item
            name={['_attributes', language, row.slug, 'value']}
            style={{ display: 'none' }}
            noStyle
          />
          <Form.Item label={props.label} className="attachment-file" shouldUpdate>
            {(form) => {
              const files: FileData[] =
                form.getFieldValue(['_attributes', language, row.slug, 'value']) || [];
              return (
                <FileSelector
                  slug={row.slug}
                  files={files}
                  onChange={updateFiles}
                  onAdd={addFile}
                  onDelete={removeFile}
                />
              );
            }}
          </Form.Item>
        </>
      );
    }
    return (
      <Form.Item {...props}>
        <Input />
      </Form.Item>
    );
  }

  return (
    <Row gutter={[10, 10]}>
      {propertyFields?.map((propertyField, i) => {
        return (
          <Col key={i} span={propertyField.columns}>
            <Form.Item
              style={{ display: 'none' }}
              name={['_attributes', language, propertyField.slug, 'slug']}
              initialValue={propertyField.slug}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ display: 'none' }}
              name={['_attributes', language, propertyField.slug, 'language']}
              initialValue={language}
            >
              <Input />
            </Form.Item>
            <Form.Item
              style={{ display: 'none' }}
              name={['_attributes', language, propertyField.slug, 'field_id']}
              initialValue={propertyField._id}
            >
              <Input />
            </Form.Item>
            {renderAttribute(propertyField)}
          </Col>
        );
      })}
    </Row>
  );
};

export default AttributesList;
