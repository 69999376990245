import { FormInstance } from 'antd';
import JoditEditor from 'jodit-react';
import { memo } from 'react';

type ArrayPath = (string | number)[];

export interface JoditFormProps {
  form: FormInstance<any>;
  path: string | ArrayPath;
}

const JoditForm: React.FC<JoditFormProps> = ({ form, path }) => {
  return (
    <JoditEditor
      value={form.getFieldValue(path)}
      config={
        {
          readonly: false,
          height: 300,
          tabIndex: 1,
          addNewLine: false,
          statusbar: false,
          askBeforePasteHTML: false,
          defaultActionOnPaste: 'insert_only_text',
          askBeforePasteFromWord: false,
          defaultActionOnPasteFromWord: 'insert_only_text',
          buttons: [
            'source',
            '|',
            'bold',
            'strikethrough',
            'underline',
            'italic',
            '|',
            'ul',
            'ol',
            '|',
            'outdent',
            'indent',
            '|',
            'fontsize',
            'brush',
            'paragraph',
            '|',
            'table',
            'link',
            '|',
            'align',
            'undo',
            'redo',
            '|',
            'hr',
            'eraser',
            'copyformat',
          ],
          buttonsXS: [
            'bold',
            'image',
            '|',
            'brush',
            'paragraph',
            '|',
            'align',
            '|',
            'undo',
            'redo',
            '|',
            'eraser',
            'dots',
          ],
        } as any
      }
      onBlur={(content) => form.setFieldValue(path, content)}
    />
  );
};

export default memo(JoditForm);
