import React from 'react';
import { Col, Form, FormInstance, Input, Row, Tabs, Divider } from 'antd';
import slugify from 'slugify';
import ImageList from '../ui/ImageList';
import CategorySelection from '../ui/CategorySelection';
import AttributesList from '../ui/AttributesList';
import AgentSelection from '../ui/AgentSelection';
import JoditForm from '../ui/JoditForm';
import ImagePicker from '../ui/ImagePicker';
// Interfaces
import { Gallery, Catalog } from '@/interfaces/property.interface';
// Hooks
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
// Services
import APIService from '@/services/API';

export interface PropertyFormProps {
  formRef: FormInstance<Catalog & { _attributes: any }>;
  onlyView?: boolean;
  edit?: boolean;
  onSubmit?: (values: Catalog & { _attributes: any }) => Promise<void>;
}
const PropertyForm: React.FC<PropertyFormProps> = ({
  formRef,
  onSubmit,
  onlyView,
  edit,
}) => {
  const { t, i18n } = useTranslation();
  const { data: categories } = useQuery(['categories'], () =>
    APIService.getCatalogs({
      $limit: '100',
    }),
  );
  const { data: propertyFields } = useQuery(['propertyFields'], () =>
    APIService.getPropertyFields({
      $limit: '100',
    }),
  );
  function onBlurTitle(event: any) {
    formRef.setFieldValue(
      ['slug', i18n.language],
      slugify(event.target.value, { lower: true }),
    );
  }
  /** Gallery methods */
  function addImageToGallery(image: Gallery) {
    formRef.setFieldsValue({
      gallery: [...formRef.getFieldValue('gallery'), image],
    });
  }
  function removeImageFromGallery(index: number) {
    const gallery: Gallery[] = formRef.getFieldValue('gallery');
    gallery.splice(index, 1);
    formRef.setFieldsValue({
      gallery,
    });
  }
  function updateImageGallery(index: number, image: Gallery) {
    formRef.setFieldValue(['gallery', index], image);
  }
  function sortGallery(gallery: Gallery[]) {
    for (let i = 0; i < gallery.length; i++) {
      gallery[i].sort = i;
    }
    formRef.setFieldsValue({
      gallery,
    });
  }
  return (
    <Form
      name="property"
      form={formRef}
      onFinish={onSubmit}
      layout="vertical"
      disabled={onlyView}
      initialValues={{
        title: {
          en: '',
          es: '',
        },
        slug: {
          en: '',
          es: '',
        },
        content: {
          en: '',
          es: '',
        },
        gallery: [],
        catalogs: {
          en: {},
          es: {},
        },
        // Only for view
        _attributes: {
          en: {},
          es: {},
        },
        attributes: [],
        status: 'draft',
      }}
    >
      <Row gutter={[10, 10]}>
        <Divider orientation="left">{t('general.general')}</Divider>
        <Form.Item name="status" hidden></Form.Item>
        <Col span={24} lg={9} md={8} sm={12}>
          <Form.Item
            name={['title', i18n.language === 'en' ? 'es' : 'en']}
            style={{ display: 'none' }}
            noStyle
          />
          <Form.Item
            label={t('general.title')}
            name={['title', i18n.language]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input onBlur={onBlurTitle} />
          </Form.Item>
        </Col>
        <Col span={24} lg={9} md={8} sm={12}>
          <Form.Item
            name={['slug', i18n.language === 'en' ? 'es' : 'en']}
            style={{ display: 'none' }}
            noStyle
          />
          <Form.Item
            label={t('general.slug')}
            name={['slug', i18n.language]}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24} lg={6} md={8} sm={24}>
          <Form.Item
            name="agent_id"
            label={t('agents.agent')}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AgentSelection />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name={['content', 'en']} style={{ display: 'none' }} noStyle />
          <Form.Item name={['content', 'es']} style={{ display: 'none' }} noStyle />
          <Form.Item
            label={t('general.content')}
            dependencies={[['content', i18n.language]]}
          >
            {(form) => <JoditForm path={['content', i18n.language]} form={form} />}
          </Form.Item>
        </Col>
        <Divider orientation="left">{t('general.categories')}</Divider>
        <Col span={24}>
          <Tabs
            items={categories?.data.map((category) => {
              const configuration = category?.configurations?.find(
                (row) => row.language === i18n.language,
              );
              const configurationAlt = category?.configurations?.find(
                (row) => row.language === (i18n.language === 'es' ? 'en' : 'es'),
              );
              return {
                forceRender: true,
                key: configuration?.slug || '',
                label: configuration?.name || '',
                children: (
                  <>
                    <Form.Item
                      name={[
                        'catalogs',
                        i18n.language === 'es' ? 'en' : 'es',
                        configurationAlt?.slug as '',
                      ]}
                      style={{ display: 'none' }}
                      noStyle
                    />
                    <Form.Item
                      name={['catalogs', i18n.language, configuration?.slug as '']}
                    >
                      <CategorySelection
                        slug={configuration?.slug || ''}
                        placeholder={t('general.selectCategory')}
                      />
                    </Form.Item>
                  </>
                ),
              };
            })}
          />
        </Col>
        <Divider orientation="left">{t('general.multimedia')}</Divider>
        <Col span={24} lg={6} md={8}>
          <Form.Item name="image" label={t('general.mainImage')}>
            <ImagePicker
              showRemove={true}
              value={formRef.getFieldValue('image')}
              onChange={(value) => formRef.setFieldValue('image', value)}
            />
          </Form.Item>
        </Col>
        <Col span={24} lg={18} md={16}>
          <Form.Item name="gallery" style={{ display: 'none' }} noStyle />
          <Form.Item className="gallery-list" label={t('general.gallery')} shouldUpdate>
            {(form) => {
              const gallery: Gallery[] = form.getFieldValue('gallery') || [];
              return (
                <ImageList
                  images={gallery}
                  onChange={updateImageGallery}
                  onAdd={addImageToGallery}
                  onDelete={removeImageFromGallery}
                  onSort={sortGallery}
                />
              );
            }}
          </Form.Item>
        </Col>
        <Divider orientation="left">{t('general.attributes')}</Divider>
        <Col span={24}>
          <Form.Item>
            <AttributesList
              propertyFields={propertyFields?.data}
              formRef={formRef}
              language={i18n.language}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default PropertyForm;
