import { FC, useState, useCallback, memo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

export interface LocationPickerProps {
  location?: { lat: number; lng: number };
  onChange?: (location: { lat: number; lng: number }) => void;
}
const LocationPicker: FC<LocationPickerProps> = ({ location, onChange }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_APP_GOOGLE_MAPS_API_KEY,
  });
  if (!location?.lat && !location?.lng) {
    location = { lat: 17.642755, lng: -101.555664 };
  }

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLng(location?.lat as number, location?.lng);
    map.setCenter(bounds);
    setMap(map);
  }, []);
  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  const onSelect = (event: any) => {
    onChange?.({ lat: event.latLng.lat(), lng: event.latLng.lng() });
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '400px' }}
        center={location}
        options={{
          zoom: 15,
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: true,
        }}
        onClick={onSelect}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {location && <Marker position={location} />}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
};

export default memo(LocationPicker);
