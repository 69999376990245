import { Gallery } from '@/interfaces/property.interface';
import { InboxOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Upload,
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImagePicker from './ImagePicker';
import ImageDnd from './ImageDnd';
import APIService from '@/services/API';
import { RcFile } from 'antd/es/upload';
import FilesWrapper from '../files/FilesWrapper';

const { Dragger } = Upload;

export interface ImageListProps {
  images: Gallery[];
  onDelete?: (index: number) => void;
  onAdd?: (image: Gallery) => void;
  onChange?: (index: number, images: Gallery) => void;
  onSort?: (images: Gallery[]) => void;
}
const ImageList: React.FC<ImageListProps> = ({
  images,
  onDelete,
  onChange,
  onAdd,
  onSort,
}) => {
  const [formRef] = Form.useForm();
  const [editingIndex, setEditingIndex] = React.useState<number>(-1);
  const [modal, setModal] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([]);
  const [modalFiles, setModalFiles] = React.useState(false);
  const [fileList, setFileList] = React.useState<RcFile[]>([]);
  const [loading, setLoading] = React.useState(false);

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const handleCloseModal = () => {
    setModal(false);
    setEditingIndex(-1);
    setTimeout(() => {
      formRef.resetFields();
      setFileList([]);
    }, 500);
  };

  const handleSave = async () => {
    const values = formRef.getFieldsValue();
    if (editingIndex > -1) {
      onChange?.(editingIndex, values);
    } else {
      await handleUploadFiles();
    }
    handleCloseModal();
  };

  const handleMove = (dragIndex: number, hoverIndex: number) => {
    const dragCard = images[dragIndex];
    const newImages = images.filter((_, index) => index !== dragIndex);
    newImages.splice(hoverIndex, 0, dragCard);
    onSort?.(newImages);
  };

  const handleUploadFiles = async () => {
    setLoading(true);
    for (const file of fileList) {
      const response = await APIService.uploadFile(file);
      if (response.ok) {
        if (response.data) {
          onAdd?.({
            title: {
              es: '',
              en: '',
            },
            url: response.data.path,
          } as Gallery);
        }
      } else {
        message.error(t('files.error'));
      }
    }
    setLoading(false);
  };

  const handleSelectFiles = () => {
    for (const file of selectedFiles) {
      onAdd?.({
        title: {
          es: '',
          en: '',
        },
        url: file,
      } as Gallery);
    }
    setModalFiles(false);
    setSelectedFiles([]);
  };

  return (
    <div>
      <Modal
        open={modalFiles}
        onCancel={() => setModalFiles(false)}
        centered
        okText={t('files.select')}
        onOk={handleSelectFiles}
        width={800}
      >
        <div className="dynamic-form">
          <FilesWrapper
            initialLayout="list"
            form
            formProps={{
              value: selectedFiles,
              dataIndex: 'path',
              multiple: true,
              onChange: (value) => {
                setSelectedFiles(value as unknown as string[]);
              },
            }}
          />
        </div>
      </Modal>
      <Modal
        title="Image"
        open={modal}
        onCancel={handleCloseModal}
        centered
        okText={t('general.save')}
        footer={
          <Row justify="space-between">
            <Col>
              <Button
                key="delete"
                type="primary"
                danger
                onClick={() => {
                  onDelete?.(editingIndex);
                  handleCloseModal();
                }}
                style={{
                  display: editingIndex > -1 ? 'inline' : 'none',
                }}
              >
                {t('general.delete')}
              </Button>
            </Col>
            <Col>
              <Button
                key="cancel"
                type="default"
                onClick={handleCloseModal}
                style={{ marginRight: 20 }}
              >
                {t('general.close')}
              </Button>
              <Button key="submit" type="primary" onClick={handleSave} loading={loading}>
                {t('general.save')}
              </Button>
            </Col>
          </Row>
        }
      >
        {editingIndex === -1 ? (
          <Dragger
            name="file"
            onRemove={() => setFileList([])}
            fileList={fileList}
            beforeUpload={(_, FileList) => {
              setFileList(FileList);
              return false;
            }}
            accept="image/*, .pdf, video/*"
            multiple
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('files.message')}</p>
          </Dragger>
        ) : (
          <Form
            form={formRef}
            initialValues={{
              title: {
                es: '',
                en: '',
              },
              url: '',
            }}
          >
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form.Item noStyle shouldUpdate>
                  {(form) => {
                    return (
                      <Form.Item label="Image" name="url">
                        <ImagePicker
                          onChange={(value) => form.setFieldValue('url', value)}
                          value={form.getFieldValue('url')}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('general.title')}
                  style={{ display: language === 'en' ? 'block' : 'none' }}
                  name={['title', 'en']}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('general.title')}
                  style={{ display: language === 'es' ? 'block' : 'none' }}
                  name={['title', 'es']}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col style={{ display: 'none' }}>
                <Form.Item label={t('general.sort')} name="sort">
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
      <Image.PreviewGroup
        preview={{
          visible: false,
          onVisibleChange: (visible, _, current) => {
            formRef.setFieldsValue(images[current]);
            setEditingIndex(current);
            setModal(visible);
          },
        }}
      >
        {images
          .sort((a, b) => a?.sort - b?.sort)
          .map((image, index) => (
            <ImageDnd
              {...{ image, index }}
              key={index}
              onMove={handleMove}
              onDelete={() => {
                onDelete?.(index);
              }}
            />
          ))}
      </Image.PreviewGroup>
      <Row justify="space-between" gutter={[10, 10]}>
        <Col span={24}>
          <Button
            style={{ width: '100%' }}
            type="dashed"
            onClick={() => {
              setModalFiles(true);
            }}
          >
            {t('files.select')}
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ImageList;
