export function extractLatLng(url: string) {
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+),/;
  const match = url.match(regex);
  if (match) {
    return {
      lat: parseFloat(match[1]),
      lng: parseFloat(match[2]),
    };
  }
  return -1;
}
