import APIService from '@/services/API';
import { Select } from 'antd';
import { useQuery } from 'react-query';

const AgentSelection: React.FC = ({ ...props }) => {
  const { data: categories, isLoading } = useQuery(['agents'], () =>
    APIService.getAgents({
      $limit: '100',
    }),
  );
  return (
    <Select
      style={{ width: '100%' }}
      loading={isLoading}
      showSearch
      options={categories?.data?.map((row) => ({
        label: row.user?.full_name,
        value: row.user?._id,
      }))}
      {...props}
    />
  );
};

export default AgentSelection;
