import { extractLatLng } from '@/utils/maps';
import { LinkOutlined } from '@ant-design/icons';
import { FormInstance, Input } from 'antd';
import { Button, Form, Modal } from 'antd';
import { FC, useState } from 'react';

export interface MapsToolsModalProps {
  onOk?: (location: { lat: number; lng: number }) => void;
}
export const ToolsForm: FC<{
  formRef: FormInstance<{
    url: string;
  }>;
  onSubmit?: (values: { url: string }) => void;
}> = ({ formRef, onSubmit }) => {
  return (
    <Form form={formRef} onFinish={onSubmit} layout="vertical">
      <Form.Item
        label={'Google Maps URL'}
        name="url"
        rules={[
          () => ({
            validator(_, value) {
              const latLng = extractLatLng(value);
              if (value && latLng !== -1) {
                return Promise.resolve();
              }
              return Promise.reject('Invalid URL');
            },
          }),
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
const MapsToolsModal: FC<MapsToolsModalProps> = ({ onOk }) => {
  const [visible, setVisible] = useState(false);
  const [formInstance] = Form.useForm();
  return (
    <>
      <Modal
        centered
        open={visible}
        onOk={() => {
          formInstance.submit();
        }}
        onCancel={() => {
          setVisible(false);
          formInstance.resetFields();
        }}
      >
        <ToolsForm
          formRef={formInstance}
          onSubmit={(values) => {
            const result = extractLatLng(values.url);
            if (result !== -1) {
              onOk?.(result);
              setVisible(false);
              formInstance.resetFields();
            }
          }}
        />
      </Modal>
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        icon={<LinkOutlined />}
      ></Button>
    </>
  );
};
export default MapsToolsModal;
