import APIService from '@/services/API';
import { Select } from 'antd';
import { useQuery } from 'react-query';

export interface CategorySelectionProps {
  slug: string;
  placeholder: string;
}

const CategorySelection: React.FC<CategorySelectionProps> = ({
  slug,
  placeholder,
  ...props
}) => {
  const { data: categories, isLoading } = useQuery(['categories', slug], () =>
    APIService.getMetaCategories({
      slug,
    }),
  );
  return (
    <Select
      mode="tags"
      loading={isLoading}
      placeholder={placeholder}
      options={categories?.data[0]?.records.map((row) => ({
        label: row.description,
        value: row.slug,
      }))}
      {...props}
    />
  );
};

export default CategorySelection;
