import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import FilesWrapper from '../files/FilesWrapper';
import FileIcon from './FileIcon';
import { getFileInfo } from '@/utils/files';
import { File } from '@/interfaces/files.interface';

export interface FilePickerProps {
  value?: string;
  onChange?: (value: string, file: File) => void;
}

const FilePicker: React.FC<FilePickerProps> = ({ value, onChange }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Modal open={modal} onCancel={() => setModal(false)} centered footer={null}>
        <div className="dynamic-form">
          <FilesWrapper
            initialLayout="list"
            form
            formProps={{
              value: value || '',
              dataIndex: 'path',
              onChange: (value, file) => {
                onChange?.(value, file);
                setModal(false);
              },
            }}
          />
        </div>
      </Modal>
      {!value ? (
        <Button type="primary" onClick={() => setModal(true)}>
          Select File
        </Button>
      ) : (
        <FileIcon
          fileType={getFileInfo(value).extension}
          style={{
            cursor: 'pointer',
            fontSize: 100,
          }}
          onClick={() => setModal(true)}
        />
      )}
    </>
  );
};

export default FilePicker;
